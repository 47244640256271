<template>
  <div class="page" v-loading.fullscreen.lock="loading">
    <div class="left panel">
      <div class="header-title">
        共{{ questionList.length || 0 }}道题
      </div>
      <div class="question-list">
        <question-stem
          class="qs"
          v-for="(item, index) in questionList"
          :key="index"
          :value="item"
          :selected="curIndex === index"
          @select="selectQuestion(item, index)"
          @pageChange="pageChange"
        />
        <div class="nomoredata">没有更多数据了~</div>
      </div>
    </div>
    <div class="right panel">
      <div class="imgArea">
        <SectionPicker
          class="imgBox"
          ref="sectionPicker"
          @change="sectionChange"
        />
        <div class="pageNumBox">
          <ui-pagination
            @current-change="getPageNumUrl"
            :current-page.sync="pageCurrent"
            :pager-count="5"
            :page-list="pageList"
            small
            layout="prev, pager, next, jumper, ->"
          />
          <el-button size="small" type="primary" @click="handSave">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import questionStem from './modules/questionStem.vue'
import SectionPicker from './modules/SectionPicker'
import { getQuestionBankImage, getQuestionListByClassHourId } from '@/api/branchImport.js'
import { questionFind, questionSaveCoordinate } from '@/api/recordQuestion'

export default {
  name: 'DrawCoordinates',
  components: {
    questionStem,
    SectionPicker
  },
  data () {
    return {
      loading: false,
      curIndex: null, // 当前选中题目index
      questionList: [], // 课时内题目列表
      pageList: [], // 教材页面列表
      lessonInfo: '', // 课时名称

      pageCurrent: 1, // 当前操作页页码
      curPage: null, // 当前操作页
      curSectionList: [] // 当前操作选区 - （当前选中题目选区）
    }
  },
  mounted () {
    const { params, query } = this.$route
    console.log('this.$route: ', params, query)

    this.loading = true
    Promise.all([
      // - 加载教材图片列表
      this.loadPageList(params.teachingMaterialId),
      // - 加载题目
      this.loadData({
        teachingMaterialId: params.teachingMaterialId,
        questionId: query.questionId
      })
    ]).then(() => {
      if (this.pageList.length && this.questionList.length) {
        this.$refs.sectionPicker.init(true)

        this.curIndex = 0

        const question = this.questionList[0]
        console.log(question)
        // 设置当前页
        if (question && question.coordinateInfo && question.coordinateInfo.length) {
          const pageInfo = question.coordinateInfo[0]
          const n = this.pageList.findIndex(m => m.no === pageInfo.page)
          const pageIndex = n < 0 ? 0 : n
          this.pageCurrent = pageIndex + 1
          this.curPage = this.pageList[pageIndex]
          // this.getPageNumUrl(this.curPage)
          this.selectQuestion(question, 0)
        }
      }
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    // 加载题目详情
    loadData (params) {
      return questionFind(params).finally(() => {
        this.questionList = []
      }).then(res => {
        if (res.code === 0) {
          if (res.data) {
            this.questionList.push(res.data)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 加载图片列表
    loadPageList (teachingMaterialId) {
      return getQuestionBankImage(teachingMaterialId).then(res => {
        if (res.code === 0) {
          this.pageList = res.data
            .sort((a, b) => a.page - b.page)
            .map((m, index) => ({ no: m.page, url: m.questionImage }))
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      })
    },

    // 选区变更
    sectionChange (list) {
      if (this.curIndex === null) {
        this.curSectionList = []
        this.getPageNumUrl(this.curPage)
        return this.$message.error('请先选择题目')
      }
      this.curSectionList = this.curSectionList
        .filter(sec => sec.pageId !== this.curPage.no) // 删除当前页旧坐标
        .concat(list.map(m => ({
          pageId: this.curPage.no,
          coordinate: this.handlerPosition(m.point)
        }))) // 添加新坐标
    },

    // 当前题目标记页 => 事件冒泡：刷新当前页
    pageChange (pageNum) {
      const page = this.pageList.find(m => m.page === pageNum)
      if (page) {
        this.curPage = {
          no: page.page,
          url: page.questionImage
        }
      }
    },

    // 选择题目
    selectQuestion (question, index) {
      this.curIndex = index
      // 更新选区状态
      const list = []
      question.coordinateInfo && question.coordinateInfo.forEach(m => {
        m.coordinate.forEach(coordinate => {
          list.push({
            pageId: m.page,
            coordinate
          })
        })
      })
      this.curSectionList = list

      this.getPageNumUrl(this.curPage)
    },

    // 保存
    handSave () {
      if (this.curIndex === null) {
        return this.$message.error('请修改题目选区后再保存数据')
      }
      if (this.curSectionList.length === 0) {
        return this.$message.error('本题没有指定选区')
      }
      const msg = this.curSectionList.length === 0
        ? '本题没有指定选区，确认是否保存'
        : '是否确认保存当前操作?'
      this.$confirm(msg, '提示').then(() => {
        const coordinates = {}
        this.curSectionList.forEach(section => {
          if (section.pageId) {
            if (!coordinates[section.pageId]) {
              coordinates[section.pageId] = []
            }
            coordinates[section.pageId].push(section.coordinate)
          }
        })
        const question = this.questionList[this.curIndex]
        const params = {
          questionId: question.questionId,
          teachingMaterialId: this.$store.state.branchImport.searchData.teachingMaterialId,
          coordinateInfo: Object.keys(coordinates).map(pageId => ({
            page: Number(pageId),
            coordinate: coordinates[pageId]
          }))
        }

        this.loading = true
        questionSaveCoordinate([params]).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.setQuestionCoordinate(question, this.curSectionList)
          } else {
            this.$message.error(res.msg)
          }
        }).finally(() => {
          this.loading = false
        })
      }).catch(() => {})
    },

    // 当前选区保存到题目中
    setQuestionCoordinate (question, cData) {
      const pageGroup = {}
      cData.forEach(m => {
        if (!pageGroup[m.pageId]) {
          pageGroup[m.pageId] = {
            page: m.pageId,
            coordinate: []
          }
        }
        pageGroup[m.pageId].coordinate.push(m.coordinate)
      })
      question.coordinate = Object.values(pageGroup)
    },

    // 翻页事件
    getPageNumUrl (pageInfo) {
      console.log('===== ', pageInfo)
      const { no, url } = pageInfo
      this.pageCurrent = no
      this.curPage = pageInfo
      // 排除当前选中题目
      const questionList = this.questionList.filter((m, index) => index !== this.curIndex)
      const sections = this.pageCoordinateDate(no, questionList, this.curSectionList)
      this.$refs.sectionPicker.pageRender(no, url, sections)
    },

    // 构建 页面-选区 数据
    pageCoordinateDate (pageId, questionList, curSectionList) {
      const result = []
      questionList.forEach(question => {
        question.coordinateInfo && question.coordinateInfo.forEach(c => { // c: { 页ID, 选区列表 }
          if (c.page === pageId) {
            c.coordinate.forEach(coordinate => {
              result.push(Object.assign({
                label: question.titleNumber || '',
                data: question
              }, coordinate))
            })
          }
        })
      })
      // 加载临时选区 (翻页至有新选区页面）
      curSectionList.forEach(c => {
        if (c.pageId === pageId) {
          result.push(Object.assign({
            isActive: true,
            label: '',
            data: null
          }, c.coordinate))
        }
      })
      return result
    },

    // 根据课时id获取所有题目
    getQuestionListByClassHourId (classHourId, materialId) {
      this.loading = true
      return getQuestionListByClassHourId(classHourId, materialId).then(res => {
        if (res.code === 0) {
          this.questionList = res.data
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      }).finally(() => {
        this.loading = false
      })
    },

    // 处理坐标数据
    handlerPosition (point) {
      return {
        x_min: +point[0].x.toFixed(2),
        y_min: +point[0].y.toFixed(2),
        x_max: +point[2].x.toFixed(2),
        y_max: +point[2].y.toFixed(2)
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.page {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  // 滚动条样式
  ::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }
  // 滚动条内部滑块样式
  ::-webkit-scrollbar-thumb{
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
    background-color:#F8F8F8;
  }

  .panel {
    box-sizing: border-box;
    width: calc(100vw / 2 - 15px);
    height: calc(100vh - 80px);

    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
  }

  .left{
    .header-title{
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 20px;
    }
    .question-list{
      height: calc(100% - 45px);
      overflow: auto;
      .qs{
        margin-bottom: 10px;
        &:nth-last-child(1){
          margin-bottom: 0;
        }
      }
      .nomoredata{
        text-align: center;
        padding: 5px 0;
        font-size: 13px;
        color: #999;
      }
    }
  }
  .right{

    .imgArea{
      .imgBox{
        height: calc(100vh - 160px);
        position: relative;
        overflow: scroll;
      }
      .pageNumBox{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  /deep/ .el-button--primary{
    background-color: #18C2C2;
    border: 1px solid #18C2C2;
    letter-spacing: 1px;
    width: 80px;
  }
}
</style>
